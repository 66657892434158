import request from './request'

//用户权限包列表
export const AuthTeamIndexRequest = p => {
    return request({
        method:'GET',
        url:'AuthTeam/index',
        params: p
    })
}

//添加用户权限包
export const AuthTeamAddRequest = d => {
    return request({
        method:'POST',
        url:'AuthTeam/add',
        data: d
    })
}

//删除用户权限包
export const AuthTeamDelRequest = id => {
    return request({
        method:'DELETE',
        url:'AuthTeam/del',
        data: {
            id: id
        }
    })
}

//修改用户权限包
export const AuthTeamEditRequest = data => {
    return request({
        method:'PUT',
        url:'AuthTeam/edit',
        data
    })
}

//获取用户权限包详情
export const AuthTeamDetailsRequest = id => {
    return request({
        method:'GET',
        url:'AuthTeam/details',
        params: {
            id
        }
    })
}

//用户权限包序列
export const AuthTeamListRequest = p => {
    return request({
        method:'GET',
        url:'AuthTeam/list',
        params: p
    })
}

//获取所有权限（角色授权调取）
export const GetAllAuthRequest = () => {
    return request({
        method:'GET',
        url:'GetAllAuth'
    })
}

//获当前权限包权限（角色授权调取）
export const GetCurrentTeamAuthRequest = id => {
    return request({
        method:'GET',
        url:'GetCurrentTeamAuth',
        params: {
            id
        }
    })
}