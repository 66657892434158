<template>
    <div v-loading.fullscreen.lock="loading">
        <auth-team-search-bar-component
            AddAuth="用户权限包列表"
            @search="get_auth_team_index"
            @addTeam="addTeam"
        ></auth-team-search-bar-component>
        <common-table-component
            details_auth="用户权限包详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_auth_team"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_auth_team_index"
        ></common-page-component>
        <auth-team-edit-component
            edit_auth="修改用户权限包"
            del_auth="删除用户权限包"
            :id="fid"
            :show_type="edit_type"
            :dialogFormVisible="fdialogFormVisible"
            :auth_team_details_data="fauth_team_details_data"
            :AllAuth="AllAuth"
            :CurrentTeamAuth="CurrentTeamAuth"
            @exitAuthTeamDialog="dialogExit"
            @search="get_auth_team_index"
            @show_edit="show_edit"
            @cancel_edit="cancel_edit"
            @details_row="details_auth_team"
        ></auth-team-edit-component>
    </div>
</template>

<script>
import { AuthTeamIndexRequest,AuthTeamDetailsRequest } from '@/network/AuthTeam.js'

import { GetAllAuthRequest } from '@/network/AuthTeam.js'
import { GetCurrentTeamAuthRequest } from '@/network/AuthTeam.js'

import AuthTeamSearchBarComponent from '@/components/AuthTeam/AuthTeamSearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'
import AuthTeamEditComponent from '@/components/AuthTeam/AuthTeamEditComponent'

export default {
    name: 'AuthTeamView',
    data() {
        return {
            fid: 0,
            edit_type: 0,
            fdialogFormVisible: false,
            loading: true,
            ftotal: 0,
            cond: {
                name: '',
                page: 1,
                limit: 10,
            },
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'name',
                    label: '权限包名称',
                    minWidth: '600px'
                },
                {
                    prop: 'create_staff_name',
                    label: '创建人',
                    minWidth: '200px'
                },
                {
                    prop: 'create_time',
                    label: '最后修改时间',
                    minWidth: '160px'
                }
            ],
            fauth_team_details_data: {},
            AllAuth: [],
            CurrentTeamAuth: []
        }
    },
    computed: {},
    methods: {
        addTeam() {
            this.edit_type = 1
            this.fdialogFormVisible = true
        },
        show_edit() {
            this.edit_type = 2
        },
        cancel_edit() {
            this.edit_type = 0
        },
        get_auth_team_index(param = {}) {
            this.loading = true
            this.cond.name = param.name ?? this.cond.name
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            AuthTeamIndexRequest(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        details_auth_team(id) {
            this.fid = id
            this.fdialogFormVisible = true
            this.get_current_team_auth(id)

            AuthTeamDetailsRequest(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.fauth_team_details_data = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        dialogExit() {
            this.fdialogFormVisible = false
            this.fid = 0
            this.edit_type = 0
        },
        get_all_auth() {
            GetAllAuthRequest()
                .then((s) => {
                    if (s.status === 0) {
                        this.AllAuth = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        get_current_team_auth(id) {
            GetCurrentTeamAuthRequest(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.CurrentTeamAuth = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },
    filters: {},
    props: {},
    created() {
        this.get_auth_team_index()
        this.get_all_auth()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        AuthTeamSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        AuthTeamEditComponent,
    },
    watch: {},
}
</script>

<style lang='less' scoped></style>