<template>
    <div>
        <el-dialog
            :title="dialogFormTitle"
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="520px"
            :center="true"
            top="20vh"
            :close-on-click-modal="false"
            :before-close="() => closeAuthTeamDialog('AuthTeamEditForm')"
            v-loading.fullscreen.lock="login_loading"
        >
            <div>
                <el-form
                    :model="AuthTeam"
                    :rules="rules"
                    ref="AuthTeamEditForm"
                    label-width="100px"
                    size="small"
                >
                    <div v-if="show_type">
                        <el-descriptions
                            class="margin-top"
                            :column="1"
                            :labelStyle="{'width':'120px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center'}"
                            border
                        >
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>权限包名称</span>
                                </template>
                                <el-input v-model="AuthTeam.name" clearable></el-input>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>包含权限</span>
                                </template>
                                <el-tree
                                    :data="AllAuth"
                                    show-checkbox
                                    node-key="id"
                                    ref="auth_tree"
                                    :props="defaultProps"
                                    :default-expanded-keys="[]"
                                    :default-checked-keys="CurrentTeamAuth"
                                >
                                </el-tree>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                    <div v-else>
                        <el-descriptions
                            class="margin-top"
                            :column="1"
                            :labelStyle="{'width':'120px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center'}"
                            border
                        >
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>权限包名称</span>
                                </template>
                                <span>{{AuthTeam.name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>包含权限</span>
                                </template>
                                <span>{{AuthTeam.routerAuth}}</span>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <el-button
                        v-if="$_has(edit_auth)"
                        type="primary"
                        plain
                        @click="$emit('show_edit')"
                        >修改</el-button
                    >
                    <el-button
                        v-if="$_has(del_auth)"
                        type="danger"
                        plain
                        @click="AuthTeamDel('AuthTeamEditForm')"
                        >删除</el-button
                    >
                    <el-button
                        plain
                        @click="closeAuthTeamDialog('AuthTeamEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else-if="show_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('AuthTeamEditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm('AuthTeamEditForm')"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closeAuthTeamDialog('AuthTeamEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else>
                    <el-button
                        type="success"
                        plain
                        @click="submitEdit('AuthTeamEditForm')"
                        >确定修改</el-button
                    >
                    <el-button
                        plain
                        @click="$emit('cancel_edit')"
                        >取消操作</el-button
                    >
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { AuthTeamAddRequest,AuthTeamDelRequest,AuthTeamEditRequest } from '@/network/AuthTeam.js'
import { GetAllAuthRequest } from '@/network/AuthTeam.js'

export default {
    name: 'AuthTeamEditComponent',
    data() {
        return {
            login_loading: false,
            AuthTeam: {
                name: '',
                routerAuth: '',
            },
            defaultProps: {
                children: 'children',
                label: 'label'
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入分组名称',
                        trigger: 'blur',
                    },
                    {
                        min: 2,
                        max: 16,
                        message: '长度在 2 到 16 个字符',
                        trigger: 'blur',
                    },
                ],
            }
        }
    },
    computed: {
        dialogFormTitle() {
            if(this.show_type === 0) {
                return '权限包详情'
            } else if (this.show_type === 1) {
                return '添加权限包'
            } else {
                return '修改权限包'
            }
        },
    },
    methods: {
        closeAuthTeamDialog(formName) {
            this.resetForm(formName)
            this.$emit('exitAuthTeamDialog')
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.AuthTeamInsert(formName)
            })
        },
        AuthTeamInsert(formName) {
            this.login_loading = true
            this.AuthTeam.routerAuth = this.getCheckedKeys()
            AuthTeamAddRequest(this.AuthTeam)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitAuthTeamDialog')
                        this.$emit('search')
                        this.$emit('details_row',s.result.id*1)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        resetForm(formName) {
            this.AuthTeam = {
                name: '',
                routerAuth: '',
            }
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.AuthTeamEdit(formName)
            })
        },
        AuthTeamEdit(formName) {
            this.login_loading = true
            this.AuthTeam.id = this.id
            this.AuthTeam.routerAuth = this.getCheckedKeys()
            AuthTeamEditRequest(this.AuthTeam)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitAuthTeamDialog')
                        this.$emit('search')
                        this.$emit('details_row',this.AuthTeam.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        AuthTeamDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    AuthTeamDelRequest(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeAuthTeamDialog(formName))
                })
                .catch((err) => {})
        },
        getCheckedKeys() {
            const arr = this.$refs.auth_tree.getCheckedKeys().filter( v => v !== undefined)
            return arr
        }
    },
    filters: {},
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        auth_team_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        edit_auth: String,
        del_auth: String,
        AllAuth: {
            type: Array,
            default() {
                return []
            }
        },
        CurrentTeamAuth: {
            type: Array,
            default() {
                return []
            }
        }
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {
        auth_team_details_data(newVal) {
            this.AuthTeam = newVal
        }
    },
}
</script>

<style lang='less' scoped></style>